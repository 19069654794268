import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {ISiteVisit} from 'app/blocks/model/site-visit.model';
import {ICustomer} from 'app/blocks/model/customer.model';
import {IServiceOrderItem} from 'app/blocks/model/service-order-item.model';
import {IEquipment} from 'app/blocks/model/equipment.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {DiTime} from 'app/blocks/util/di-time';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IContactInfo} from 'app/blocks/model/contact-info.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IFranchise} from 'app/blocks/model/franchise.model';
import {Mposfile} from 'app/blocks/model/mpos-file.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IRoute} from 'app/blocks/model/route.model';
import {INote} from 'app/blocks/model/note.model';
import {IDiscount} from 'app/blocks/model/discount.model';
import {IVehicle} from 'app/blocks/model/vehicle.model';

export interface IServiceOrder extends IBaseFranchiseEntity {
    id?: number;
    serviceOrderNo?: string;
    customer?: ICustomer;
    deliveryPoint?: IDeliveryPoint;
    equipment?: IEquipment;
    siteVisit?: ISiteVisit;
    scheduledDate?: any;
    scheduledTime?: any;
    price?: number;
    description?: string;
    serviceOrderItems?: IServiceOrderItem[];
    serviceOrderStatus?: string;
    deliveryAddress?: IContactInfo;
    serviceDocuments?: Mposfile[];
    requestDate?: any;
    requestTime?: any;
    requestTakenBy?: IEmployee;
    route?: IRoute;
    notes?: INote;
    internalNotes?: INote;
    discount?: IDiscount;
    vehicle?: IVehicle;
    orderTotal?: number;
}

export class ServiceOrder extends BaseFranchiseEntity implements IServiceOrder {
    public id?: number;
    public serviceOrderNo?: string;
    public customer?: ICustomer;
    public deliveryPoint?: IDeliveryPoint;
    public equipment?: IEquipment;
    public siteVisit?: ISiteVisit;
    public scheduledDate?: any;
    public scheduledTime?: any;
    public price?: number;
    public description?: string;
    public serviceOrderItems?: IServiceOrderItem[];
    public serviceOrderStatus?: string;
    public deliveryAddress?: IContactInfo;
    public serviceDocuments?: Mposfile[];
    public requestDate?: any;
    public requestTime?: any;
    public requestTakenBy?: IEmployee;
    public route?: IRoute;
    public notes?: INote;
    public internalNotes?: INote;
    public discount?: IDiscount;
    public vehicle?: IVehicle;
    public orderTotal?: number;

    constructor(serviceOrder?) {
        super();
        if (serviceOrder) {
            this.id = serviceOrder.id;
            this.serviceOrderNo = serviceOrder.serviceOrderNo;
            this.customer = serviceOrder.customer;
            this.deliveryPoint = serviceOrder.deliveryPoint;
            this.equipment = serviceOrder.equipment;
            this.siteVisit = serviceOrder.siteVisit;
            this.scheduledDate = MposDate.newInstance(serviceOrder.scheduledDate);
            this.scheduledTime = DiTime.newInstance(serviceOrder.scheduledTime);
            this.price = serviceOrder.price;
            this.description = serviceOrder.description;
            this.serviceOrderItems = serviceOrder.serviceOrderItems;
            this.serviceOrderStatus = serviceOrder.serviceOrderStatus;
            this.deliveryAddress = serviceOrder.deliveryAddress;
            this.editable = serviceOrder.editable;
            this.assignedFranchise = serviceOrder.assignedFranchise;
            this.serviceDocuments = serviceOrder.serviceDocuments;
            this.requestDate = MposDate.newInstance(serviceOrder.requestDate);
            this.requestTime = DiTime.newInstance(serviceOrder.requestTime);
            this.requestTakenBy = serviceOrder.requestTakenBy;
            this.route = serviceOrder.route;
            this.notes = serviceOrder.notes;
            this.internalNotes = serviceOrder.internalNotes;
            this.discount = serviceOrder.discount;
            this.vehicle = serviceOrder.vehicle;
            this.orderTotal = serviceOrder.orderTotal;
        } else {
            this.serviceOrderStatus = 'PENDING';
        }
    }

    get discriminator(): any {
        return this.serviceOrderNo;
    }

    clone(): ServiceOrder {
        return new ServiceOrder(this);
    }
}
