import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IServiceOrder, ServiceOrder} from 'app/blocks/model/service-order.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {FormArray, UntypedFormBuilder} from '@angular/forms';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class ServiceOrderService extends EntityCrudService<IServiceOrder> {
    constructor(
        http: HttpClient,
        public _fb: UntypedFormBuilder
    ) {
        super(http, Resource.SERVICE_ORDERS, (obj?) => new ServiceOrder(obj));
    }

    confirmServiceOrder = (id): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/service-orders/' + id + '/confirm', null, {observe: 'response'}).toPromise();
    };

    uploadFile = (label: string, file: File, serviceOrderId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('label', label);
        return this.http.post<any>(`${SERVER_API_URL}api/service-order/${serviceOrderId}/docs`, fd).toPromise();
    };

    downloadFile = (serviceOrderId: number, mposFileId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/service-orders/${serviceOrderId}/docs/${mposFileId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    getAllEquipmentsForTheSelectedCustomer = (customerId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/service-orders/${customerId}/equipments`;
        return this.http.get<any>(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    cancelServiceOrder = (id: number): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/service-orders/' + id + '/cancel', null, {observe: 'response'}).toPromise();
    };

    deleteFile = (serviceOrderId: number, mposFileId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/service-orders/${serviceOrderId}/docs/${mposFileId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    downloadServiceOrdersPDF = (ids: number[]): Promise<any> => {
        const requestParams = createRequestOption({ids: ids});
        const REQUEST_URI = `${SERVER_API_URL}api/service-orders/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    public serviceOrderFilterForRedirect = (id: any, status: any): Promise<HttpResponse<any>> => {
        return this.http
            .get<any>(SERVER_API_URL + `api/service-orders?page=0&size=25&sort=id,desc&query=&serviceOrderStatus=${status}&equipment=${id}`, {
                observe: 'response'
            })
            .toPromise();
    };

    markAsApproved = (ids: number[]): any => {
        const requestParams = new HttpParams().set('ids', ids.join(','));
        return this.http
            .post<any>(SERVER_API_URL + 'api/service-orders/mark-as-approved', null, {observe: 'response', params: requestParams})
            .toPromise();
    };

    rescheduleSelected = (request): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/service-orders/reschedule', request, {
                observe: 'response'
            })
            .toPromise();
    };

    servicedServiceOrder = (id): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/service-orders/' + id + '/serviced', null, {observe: 'response'}).toPromise();
    };
}
